import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Link } from "react-router-dom";

import { web3 } from '../../store/web3';
import '../../app/HBurnMarketplace/index.css';
import { networkId, message } from '../../store/config';
import { login, logout, setAddress } from '../../store/actions/Auth';
import sidebararrow from "../../static/images/arrow-right.svg";

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            address: '',
            token: '',
            errorText: '',
            isSidebarActive: false,
            data: props?.userData ? props?.userData : {},
        };

        this.toggleSidebar = this.toggleSidebar.bind(this);
    };

    toggleSidebar() {
        this.setState((prevState) => ({
            isSidebarActive: !prevState.isSidebarActive,
        }));
    }

    componentWillReceiveProps({ publicAddress, userData }) {
        if (publicAddress) {
            this.setState({ address: publicAddress })
        } else {
            EventBus.publish('info', `Connect your metamask wallet!`);
        }

        if (Object.keys(userData).length > 0) {
            this.setState({ data: userData });
        } else {
            EventBus.publish('info', `User must login/register to access platform modules`);
        }
    }

    connectWallet = async () => {
        if (typeof window.ethereum === 'undefined') {
            EventBus.publish('error', `Please Install Metamask!!!`);
            return;
        }

        web3.eth.net.getId(async (err, netId) => {
            if (netId != networkId) {
                EventBus.publish('info', message);
                return;
            }
            let address = (await web3.currentProvider.enable())[0];
            this.props.setAddress(address);
            this.props.login(address);
            this.setState({ address });
        });
    };

    logout() {
        // Delete all items from local storage to logout the user
        localStorage.clear();
        this.props.logout('');
        this.setState({ token: '', address: '' });
    }

    render() {
        const { isSidebarActive, data } = this.state;

        return (
            <div>
                <div className={isSidebarActive ? 'bg-open active' : 'bg-open'}>
                </div>

                <button className={isSidebarActive ? 'sidebar-handler active' : 'sidebar-handler'} onClick={this.toggleSidebar}>
                    <img src={sidebararrow} alt="" />
                </button>

                <div className={isSidebarActive ? 'sidebar active' : 'sidebar'}>
                    <div className="logo-wrap">
                        <Link to="/" class="logo">
                            <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/Oxburn96.png" alt="" />
                        </Link>
                    </div>
                    <ul>
                        <li>
                            <Link to="/profile">Profile</Link>
                        </li>

                        {
                            Object.keys(data).length > 0 ?
                                <>
                                    {/* <li>
                                        <Link to="/mint-partner-nft">Presale NFT</Link>
                                    </li> */}

                                    <li>
                                        {/* <Link to="/swap">DEX</Link> */}
                                        <Link to="#">DEX (Coming Soon)</Link>
                                    </li>

                                    {/* <li>
                                        <Link to="/leaderboard">Leaderboard</Link>
                                    </li> */}

                                    <li>
                                        <Link to="#">HBurn DAO (Coming Soon)</Link>
                                    </li>

                                    <li>
                                        {/* <Link to="/launch-dao">Launch DAO</Link> */}
                                        <Link to="#">Launch DAO (Coming Soon)</Link>
                                    </li>

                                    <li>
                                        {/* <Link to="/view-dao">View DAO</Link> */}
                                        <Link to="#">View DAO (Coming Soon)</Link>
                                    </li>

                                    {/* <li>
                                        <Link to="/assets-nft">Assets NFT</Link>
                                    </li> */}

                                    {/* <li>
                                        <Link to="/mint-hederagon-nft">Hederagon Access NFT</Link>
                                    </li> */}

                                    <li>
                                        {/* <Link to="/create-token">Create Token</Link> */}
                                        <Link to="#">Create Token (Coming Soon)</Link>
                                    </li>

                                    <li>
                                        {/* <Link to="/view-token">View Token</Link> */}
                                        <Link to="#">View Token (Coming Soon)</Link>
                                    </li>

                                    <li>
                                        {/* <Link to="/create-nft">Create NFT</Link> */}
                                        <Link to="#">Create NFT (Coming Soon)</Link>
                                    </li>

                                    <li>
                                        {/* <Link to="/view-nft">View NFT</Link> */}
                                        <Link to="#">View NFT (Coming Soon)</Link>
                                    </li>

                                    <li>
                                        {/* <Link to="/marketplace">Marketplace </Link> */}
                                        <Link to="#">Marketplace (Coming Soon)</Link>
                                    </li>

                                    {/* <li>
                                        <a href="https://bridge.hburn.co/" target="_blank">Bridge</a>
                                    </li> */}
                                </>
                                :
                                <></>
                        }
                    </ul>
                </div>
            </div>

        );
    }
}
const mapDispatchToProps = {
    login,
    logout,
    setAddress
};

const mapStateToProps = ({ Auth }) => {
    let { publicAddress, isLogin, userData } = Auth;
    return { publicAddress, isLogin, userData }
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);