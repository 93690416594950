import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import { NavLink, Link, useParams } from "react-router-dom";
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { CopyToClipboard } from "react-copy-to-clipboard";


import './index.css';
import { web3 } from '../../store/web3';
import Sidebar from '../../components/sidebar';
import Footer from '../../components/footer';
import {NFTABI} from '../../store/contract/index';
import { toggleLoader, getCollection, getUser, getCollectionDetails, addRemoveFavItems } from "../../store/actions/Auth";

const alternate = "https://ox-burn-assets.s3.us-east-2.amazonaws.com/collection-img.png";

const AssetsNFT = (props) => {

    let { tokenAddress, chain } = useParams();
    let [maxSupply, setMaxSupply] = useState(0);
    let [totalMint, setTotalMint] = useState(0);
    let [price, setPrice] = useState(0);
    let [order, setOrder] = useState("asc");
    let [filter, setFilter] = useState("");
    let [handleChange, setHandleChange] = useState("");
    let [collection, setCollection] = useState();
    let [nfts, setNfts] = useState([]);

    const [itemPerRow, setItemPerRow] = useState(10);
    const [next, setNext] = useState(itemPerRow);

    useEffect(() => {
        props.toggleLoader({status:true,message:"Load collection"});
        props.getCollectionDetails({ tokenAddress, chain });
    }, [props.chain])

    const makeContract = async (contractAddress, type) => {
        let Token = new web3.eth.Contract(NFTABI, contractAddress);
            let totalMint = await Token.methods.totalSupply().call();
            let maxSupply = await Token.methods.maxSupply().call();
            let price = await Token.methods.priceRecipient().call();
            setMaxSupply(maxSupply);
            setTotalMint(totalMint);
            price = await web3.utils.fromWei(price.toString(), 'ether');
            setPrice(price);
    }

    useEffect(() => {
        if (Object.keys(props.singleCollectionDetails).length > 0) {
            let { collectionDetail, nfts } = props.singleCollectionDetails;
            setCollection(collectionDetail);
            if (collectionDetail.tokenType == "erc1155" && nfts.length > 0) {
                nfts = nfts.filter(item => item['mintAmount'] !== 0)
                setNfts(nfts);
            } else {
                setNfts(nfts);
            }
            makeContract(collectionDetail.tokenAddress, collectionDetail.tokenType);
        }
    }, [props.singleCollectionDetails,props.chain])

    const handleImageError = (idx) => {
        nfts[idx]['image'] = alternate;
        setNfts([...nfts]);
    };

    const handleUserImageError = (idx) => {
        nfts[idx]['users']['image'] = alternate;
        setNfts([...nfts]);
    };

    const AddFavItems = (nftId) => {
        props.addRemoveFavItems({ nftId, tokenAddress, chain });
    }

    const filterNft = async (e) => {
        e.preventDefault();
        setFilter(handleChange);
    }

    const copyAddress =()=> EventBus.publish('success',`address copy!`);

    const handleMoreImage = () => {
        setNext(next + itemPerRow);
    };

    
    return (
        <div className="wrapper outer">
            <Sidebar />

            <div className="right-wrap">
                <section className="explore pr no-top">
                    <div className="container">
                        <div className="head">
                            <h2>Assets NFT's</h2>
                        </div>

                        <div className="row">
                            <div className="col-md-6 col-lg-4 col-xl-3">
                                <Link to="/" className="card-box">
                                    <div className="card-inner">
                                        <img src={alternate} alt="..." />

                                        <h3>NFT Name</h3>                                            
                                    </div>

                                    <div className="card-bottom">
                                        <div className="price">
                                            <span className="current">Price</span>
                                            <p>
                                                <span className="bold">20 HBurn </span>
                                            </p>
                                        </div>
                                        <div className="view-history">
                                            <a href=""> Token Id 0xasdad3eqdaskndqe </a>
                                        </div>
                                    </div>
                                </Link>
                            </div>

                            <div className="col-md-6 col-lg-4 col-xl-3">
                                <Link to="/" className="card-box">
                                    <div className="card-inner">
                                        <img src={alternate} alt="..." />

                                        <h3>NFT Name</h3>                                            
                                    </div>

                                    <div className="card-bottom">
                                        <div className="price">
                                            <span className="current">Price</span>
                                            <p>
                                                <span className="bold">20 HBurn </span>
                                            </p>
                                        </div>
                                        <div className="view-history">
                                            <a href=""> Token Id 0xasdad3eqdaskndqe </a>
                                        </div>
                                    </div>
                                </Link>
                            </div>

                            <div className="col-md-6 col-lg-4 col-xl-3">
                                <Link to="/" className="card-box">
                                    <div className="card-inner">
                                        <img src={alternate} alt="..." />

                                        <h3>NFT Name</h3>                                            
                                    </div>

                                    <div className="card-bottom">
                                        <div className="price">
                                            <span className="current">Price</span>
                                            <p>
                                                <span className="bold">20 HBurn </span>
                                            </p>
                                        </div>
                                        <div className="view-history">
                                            <a href=""> Token Id 0xasdad3eqdaskndqe </a>
                                        </div>
                                    </div>
                                </Link>
                            </div>

                            <div className="col-md-6 col-lg-4 col-xl-3">
                                <Link to="/" className="card-box">
                                    <div className="card-inner">
                                        <img src={alternate} alt="..." />

                                        <h3>NFT Name</h3>                                            
                                    </div>

                                    <div className="card-bottom">
                                        <div className="price">
                                            <span className="current">Price</span>
                                            <p>
                                                <span className="bold">20 HBurn </span>
                                            </p>
                                        </div>
                                        <div className="view-history">
                                            <a href=""> Token Id 0xasdad3eqdaskndqe </a>
                                        </div>
                                    </div>
                                </Link>
                            </div>

                            <div className="col-md-6 col-lg-4 col-xl-3">
                                <Link to="/" className="card-box">
                                    <div className="card-inner">
                                        <img src={alternate} alt="..." />

                                        <h3>NFT Name</h3>                                            
                                    </div>

                                    <div className="card-bottom">
                                        <div className="price">
                                            <span className="current">Price</span>
                                            <p>
                                                <span className="bold">20 HBurn </span>
                                            </p>
                                        </div>
                                        <div className="view-history">
                                            <a href=""> Token Id 0xasdad3eqdaskndqe </a>
                                        </div>
                                    </div>
                                </Link>
                            </div>

                            <div className="col-md-6 col-lg-4 col-xl-3">
                                <Link to="/" className="card-box">
                                    <div className="card-inner">
                                        <img src={alternate} alt="..." />

                                        <h3>NFT Name</h3>                                            
                                    </div>

                                    <div className="card-bottom">
                                        <div className="price">
                                            <span className="current">Price</span>
                                            <p>
                                                <span className="bold">20 HBurn </span>
                                            </p>
                                        </div>
                                        <div className="view-history">
                                            <a href=""> Token Id 0xasdad3eqdaskndqe </a>
                                        </div>
                                    </div>
                                </Link>
                            </div>

                            <div className="col-md-6 col-lg-4 col-xl-3">
                                <Link to="/" className="card-box">
                                    <div className="card-inner">
                                        <img src={alternate} alt="..." />

                                        <h3>NFT Name</h3>                                            
                                    </div>

                                    <div className="card-bottom">
                                        <div className="price">
                                            <span className="current">Price</span>
                                            <p>
                                                <span className="bold">20 HBurn </span>
                                            </p>
                                        </div>
                                        <div className="view-history">
                                            <a href=""> Token Id 0xasdad3eqdaskndqe </a>
                                        </div>
                                    </div>
                                </Link>
                            </div>

                            <div className="col-md-6 col-lg-4 col-xl-3">
                                <Link to="/" className="card-box">
                                    <div className="card-inner">
                                        <img src={alternate} alt="..." />

                                        <h3>NFT Name</h3>                                            
                                    </div>

                                    <div className="card-bottom">
                                        <div className="price">
                                            <span className="current">Price</span>
                                            <p>
                                                <span className="bold">20 HBurn </span>
                                            </p>
                                        </div>
                                        <div className="view-history">
                                            <a href=""> Token Id 0xasdad3eqdaskndqe </a>
                                        </div>
                                    </div>
                                </Link>
                            </div>

                            <div className="col-md-6 col-lg-4 col-xl-3">
                                <Link to="/" className="card-box">
                                    <div className="card-inner">
                                        <img src={alternate} alt="..." />

                                        <h3>NFT Name</h3>                                            
                                    </div>

                                    <div className="card-bottom">
                                        <div className="price">
                                            <span className="current">Price</span>
                                            <p>
                                                <span className="bold">20 HBurn </span>
                                            </p>
                                        </div>
                                        <div className="view-history">
                                            <a href=""> Token Id 0xasdad3eqdaskndqe </a>
                                        </div>
                                    </div>
                                </Link>
                            </div>

                            <div className="col-md-6 col-lg-4 col-xl-3">
                                <Link to="/" className="card-box">
                                    <div className="card-inner">
                                        <img src={alternate} alt="..." />

                                        <h3>NFT Name</h3>                                            
                                    </div>

                                    <div className="card-bottom">
                                        <div className="price">
                                            <span className="current">Price</span>
                                            <p>
                                                <span className="bold">20 HBurn </span>
                                            </p>
                                        </div>
                                        <div className="view-history">
                                            <a href=""> Token Id 0xasdad3eqdaskndqe </a>
                                        </div>
                                    </div>
                                </Link>
                            </div>

                            <div className="col-md-6 col-lg-4 col-xl-3">
                                <Link to="/" className="card-box">
                                    <div className="card-inner">
                                        <img src={alternate} alt="..." />

                                        <h3>NFT Name</h3>                                            
                                    </div>

                                    <div className="card-bottom">
                                        <div className="price">
                                            <span className="current">Price</span>
                                            <p>
                                                <span className="bold">20 HBurn </span>
                                            </p>
                                        </div>
                                        <div className="view-history">
                                            <a href=""> Token Id 0xasdad3eqdaskndqe </a>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        
                        <a className="common-btn"> Load More <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/btn-arrow.png" alt="" /></a>
                    </div>
                </section>
                
                <Footer />
            </div>
        </div>
    );
}

const mapDispatchToProps = { toggleLoader, getCollection, getUser, getCollectionDetails, addRemoveFavItems };

const mapStateToProps = ({ Auth }) => {
    let { isLogin, chain, publicAddress, singleCollectionDetails } = Auth;
    return { isLogin, chain, publicAddress, singleCollectionDetails }
}

export default connect(mapStateToProps, mapDispatchToProps)(AssetsNFT);