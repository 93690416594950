import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import EventBus from 'eventing-bus';
import { web3 } from "../../store/web3";
import { env, networkType } from '../../store/config';
import { connectMetamask } from "../../store/walletConnect";
import { logout, getUser, setAddress, getNonce, login, setNonce, getGasAmount } from '../../store/actions/Auth';

import '../../app/HBurnMarketplace/index.css';

const Footer = (props) => {
  let [isSign, setIsSign] = useState(false);

  const handleWalletChanges = () => {
    let network = ''
    if (env == 'production') {
      network = 'avalanche';
    } else {
      network = 'avalanche';
    }
    console.log("*** network", network);

    if (network) {
      connectMetamask(network, networkType)
        .then(async ({ address }) => {
          if (address && !isSign) {
            console.log("*** 0");
            let chain = await web3.eth.getChainId();
            chain = web3.utils.hexToNumber(chain);
            chain = Number(chain);
            let balanceWei = await web3.eth.getBalance(address);
            console.log("*** address :: ", address);
            console.log("*** chain :: ", chain);
            console.log("*** balanceWei :: ", balanceWei);


            if (balanceWei < 10000000000000) {
              console.log("*** 1");
              props.getGasAmount({ publicAddress: address });
            }

            props.setAddress({ publicAddress: address, chain });
            props.getUser({ publicAddress: address });
            props.getNonce({ publicAddress: address, chain });
            setIsSign(true);
            //   return EventBus.publish("success", `wallet connect successfuly`); 
          }
        })
        .catch((error) => {
          // return EventBus.publish("error", `failed to connect wallet: ${error}`);
          return EventBus.publish("error", `Could not establish RPC connection!`);
        });
    }
  };

  const loginWallet = async (nonce) => {
    try {
      const accounts = await web3.eth.getAccounts();
      const address = accounts[0];
      if (address) {
        let chain = await web3.eth.getChainId();
        chain = web3.utils.hexToNumber(chain);
        chain = Number(chain);
        const signature = await web3.eth.personal.sign(`HBurn marketplace signature ${nonce}`, address);
        if (address && signature) props.login({ address, signature, nonce, chain })
      }
    } catch (error) {
      return EventBus.publish("error", `failed to create signature: ${error}`);
    }
  }

  useEffect(() => {
    if (props.nonce && isSign) loginWallet(props.nonce);
  }, [props.nonce])

  return (
    <>
      <button className="footer common-btn" onClick={() => handleWalletChanges()}>{props?.publicAddress ? props.publicAddress.slice(0, 6) + "..." + props.publicAddress.slice(38, 42) : "Connect Wallet"}<img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/btn-arrow.png" alt="" /></button>

      <footer>
        <div className="container">
          <div className="inner">
            <div className="copyright">
              <p>Powered by <a target="_blank" href="https://hashverse.solutions" style={{ color: "white" }}>Hashverse Solutions</a></p>
            </div>

            <ul className='social-icons'>
              <li><a target="_blank" href="https://youtube.com/@hburn-hederagon?si=eDI-aEpfJQOWLwmK"><img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/YoutubeLogo.svg" alt="" /></a></li>
              <li><a target="_blank" href="https://discord.com/invite/6FpchyjBkP"><img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/DiscordLogo.svg" alt="" /></a></li>
              <li><a target="_blank" href="https://medium.com/@HashBurn_Official"><img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/MediumLogo.svg" alt="" /></a></li>
              <li><a target="_blank" href="https://x.com/HBurn_Official"><img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/XLogo.svg" alt="" /></a></li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
}

const mapDispatchToProps = { logout, setAddress, getNonce, login, setNonce, getUser, getGasAmount };

const mapStateToProps = ({ Auth }) => {
  let { publicAddress, network, nonce, isLogin, isNonce, isChangeNetwork } = Auth;
  return { publicAddress, network, nonce, isLogin, isNonce, isChangeNetwork }
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer);